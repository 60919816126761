.loading {
  /* width: 100vw;
  height: 100vh; */
  background-color: white;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading>div {
  width: 10vmax;
  height: 10vmax;
  border-bottom: 5px solid rgba(229, 109, 179, 0.719);

  border-radius: 50%;

  animation: loadingRotate 800ms linear infinite;
}

.react-datepicker-wrapper {
  width: 100%;
}

.modal-dialog {
  max-width: 827px !important;
}

.center-table {
  text-align: center;
}

.cursor-point {
  cursor: pointer;
}

@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
}